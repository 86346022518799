@import "../../_globalColor";

.research-heading {
    font-size: 56px;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
}

.research-subtitle {
    text-transform: uppercase;
    margin: 0px;
    margin-bottom: 50px;
}

.subTitle {
    color: $subTitle;
}

/* Cards Layout */
.research-cards-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

/* Media Queries */
@media (max-width: 1380px) {
    .research-heading {
        font-size: 40px;
    }
}

@media (max-width: 768px) {
    .research-heading {
        font-size: 30px;
        text-align: center;
    }

    .research-subtitle {
        font-size: 16px;
        line-height: normal;
        text-align: center;
    }

    .research-cards-div {
        flex-direction: column;
        align-items: center;
    }
}
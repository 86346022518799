@import "../../_globalColor";

.research-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin: 15px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.research-card:hover {
    box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
}

.dark-mode.research-card {
    background-color: $darkBackground;
    border-radius: 10px;
}

.dark-mode.research-card:hover {
    box-shadow: $achievementCertificateCardHoverDark 0px 20px 30px -10px;
}

.research-title {
    font-size: 20px;
    font-weight: bold;
}

.research-description {
    margin-top: 5px;
    font-size: 16px;
}

.research-status {
    margin-top: 10px;
    font-style: italic;
}

.research-link {
    margin-top: 10px;
    color: #1e90ff;
    cursor: pointer;
}

.research-advisors {
    margin-top: 5px;
    font-size: 16px;
    font-style: italic;
}

.dark-mode.research-title,
.dark-mode.research-advisors,
.dark-mode.research-description,
.dark-mode.research-status,
.dark-mode.research-link {
    color: #fff;
}

.research-card-footer {
    display: flex;
    // align-items: center;
    flex-wrap: wrap;
    // justify-content: center;
}

.research-card-footer span.research-link {
    background: $buttonColor;
    color: $lightBackground3;
    vertical-align: middle;
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    font-size: 0.75rem;
    height: 2em;
    justify-content: center;
    white-space: nowrap;
    line-height: 1.5;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0 0.75em;
    cursor: pointer;
    transition: 0.2s ease-in;
}

span.research-link:hover {
    background: $buttonHover;
}